<script lang="ts" setup>
import iconFb from '~/assets/img/fb.svg'
import iconX from '~/assets/img/x.svg'
import iconIn from '~/assets/img/in.svg'

const Loader = useIsLoadingStore()
const globalStore = useGlobalStore()

const config = useRuntimeConfig()

const socials = ref([
    {
        link: 'https://www.facebook.com/yourserviceae',
        img: iconFb,
    },
    {
        link: 'https://x.com/yourserviceae',
        img: iconX,
    },
    {
        link: 'https://www.instagram.com/yourservice.ae',
        img: iconIn,
    },
])

</script>

<template>
    <div class="bg-stroke min-w-screen h-screen px-5 py-5 md:py-10 relative">
        <div class="max-w-[540px] mx-auto h-full flex flex-col justify-between">
          <img src="/img/ys_footer_logo.svg" alt="Yourservice"
          class="mx-auto max-h-[34px]">
            <slot />
            <div class="footer">
                <div class="flex gap-6 w-fit mx-auto">
                    <a v-for="item in socials" :href="item.link" target="_blank">
                        <Component :is="item.img" class="hover:opacity-70 transition-all"/>
                    </a>
                </div>
                <div v-if="config.public.playstoreLink || config.public.appstoreLink"
                     class="flex items-center gap-6 mx-auto w-fit mt-4">
                    <a :href="config.public.playstoreLink" target="_blank">
                        <NuxtImg class="ys-app-emblem h-[35px]" src="/home_page/googleplay.svg"/>
                    </a>
                    <a :href="config.public.appstoreLink" target="_blank">
                        <NuxtImg class="ys-app-emblem h-[35px]" src="/home_page/appstore.svg"/>
                    </a>
                </div>
                <p class="mt-4 text-sm text-gray-500 col-span-2 text-center">
                    {{ $t('© YourService International Ltd.') }}{{ new Date().getFullYear() }}
                    <template v-if="globalStore.rule_pages.privacy_page && globalStore.rule_pages.rules_page">
                        <NuxtLink v-for="page in [globalStore.rule_pages.privacy_page && globalStore.rule_pages.rules_page]" class="text-black hover:text-gray-700 transition-all" :to="`/promo/${page.documentId}`">
                            {{ page.name}}
                        </NuxtLink>
                    </template>
                </p>
            </div>
        </div>
    </div>
</template>


<style></style>